<template>
	<div class="box">
		<b-field>
			<b-select v-model="printerManager"
					  @input="onPrintManagerChange"
					  placeholder="Selecciona un manejador de Impresión">
				<option :value="$lapi_printer_managers.jsprint">JSPrint Manager</option>
				<option :value="$lapi_printer_managers.lapiPrint">Alfalab Manager</option>
        <option :value="$lapi_printer_managers.VFP">Alfalab Impresión VFP</option>
			</b-select>
		</b-field>
		<template v-if="printerManager === $lapi_printer_managers.jsprint">
			<b-field grouped>
				<b-field>
					<b-select v-model="name"
							  :loading="loading"
							  placeholder="Selecciona una impresora">
						<option v-for="item in printersList" :value="item.name">
							{{ item.name }}
						</option>
					</b-select>
				</b-field>
				<b-field>
					<b-select v-model="use"
							  :loading="loading"
							  placeholder="Selecciona su uso">
						<option v-for="item in types" :value="item.value">
							{{ item.text }}
						</option>
					</b-select>
				</b-field>
				<b-field>
					<button class="button is-success"
							type="button"
							@click="addPrint"
							:disabled="!canAddPrint">
						Agregar
					</button>
				</b-field>
			</b-field>

			<b-table :data="printers">
				<b-table-column field="name" label="Impresora" v-slot="props">
					{{ props.row.name }}
				</b-table-column>
				<b-table-column field="use" label="Uso" v-slot="props">
					{{ props.row.use }}
				</b-table-column>
				<b-table-column label="Acciones" v-slot="props">
					<button class="button is-small is-danger"
							type="button"
							@click.stop="removePrint(props)">
						<b-icon icon="times"></b-icon>
					</button>
				</b-table-column>
			</b-table>
		</template>
		<template v-if="printerManager === this.$lapi_printer_managers.lapiPrint">
			<p>
				<strong>Nota:</strong> El programa de impresión Alfalab debe estar instalado y en ejecución
				<br>Cada impresión tarda aproximadamente 10 segundos en ser generada
			</p>
      <br>
      <h2>Configuración Adicional</h2>
      <br>
      <div class="columns">
        <div class="column is-3">
          <b-field label="Número de Impresora por default 1">
            <b-input v-model="printer" type="number" @input="onStoreNoPrinter"/>
          </b-field>
        </div>
      </div>
			<h2 class="subtitle"><strong>Descagar cliente</strong></h2>
			<ul>
				<li><a :href="`${endpoint}/printer-manager/x32`" download="Alfalab Printer 32bits.msi" target="_blank">Windows 32 bits</a></li>
				<li><a :href="`${endpoint}/printer-manager/x64`" download="Alfalab Printer 64bits.exe" target="_blank">Windows 64 bits</a></li>
			</ul>
		</template>
    <template v-if="printerManager === this.$lapi_printer_managers.VFP">
      <p>
        <strong>Nota:</strong> El programa de impresión Alfalab debe estar instalado y en ejecución
        <br>Cada impresión tarda aproximadamente 10 segundos en ser generada
      </p>
      <h2 class="subtitle"><strong>Descagar cliente</strong></h2>
      <ul>
        <li>NO DISPONIBLE</li>
      </ul>
    </template>
	</div>
</template>

<script>
import * as JSPM from "jsprintmanager";

const printers = JSON.parse(localStorage.getItem('printers') || '[]');

export default {
	name: "Configure",
	data() {
		return {
			printers,
			printersList: [],
			types: [
				{value: 'docs', text: 'Documentos'},
				{value: 'labels', text: 'Etiquetas'},
			],
			use: null,
			name: null,
			loading: true,
			printerManager: '',
      printer: 1
		}
	},
	computed: {
		canAddPrint() {
			return !!this.name && !!this.use;
		},
		endpoint() {
			return process.env.VUE_APP_API_URL;
		}
	},
	mounted() {
		this.printerManager = localStorage.getItem('printerManager') || this.$lapi_printer_managers.jsprint;
		this.printer = Number.parseInt(localStorage.getItem('printerManager_printer')) || 1;
		console.log(process.env.VUE_APP_URL);
		const vm = this;
		JSPM.JSPrintManager.auto_reconnect = true;
		JSPM.JSPrintManager.start().catch(error => {
		  console.error('no jsprint ');
		  this.$toast.error('Si va usar jsprint manager es necesario tener el programa instalado y funcionando');
		});
		JSPM.JSPrintManager.WS.onStatusChanged = function () {
			if (JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open) {
				JSPM.JSPrintManager.getPrintersInfo()
					.then((printersList) => {
						vm.loading = false;
						vm.printersList = printersList;
					});
			}
		};
	},
	methods: {
		removePrint({index}) {
			this.$buefy.dialog.confirm({
				title: 'Eliminar impresora',
				message: '¿Estas seguro que deseas <b>eliminar</b> la impresora?',
				confirmText: 'Si, eliminar',
				type: 'is-danger',
				hasIcon: true,
				onConfirm: () => {
					this.printers.splice(index, 1);
					this.setPrinters();
					this.$toast.warning('Impresora eliminada');
				}
			})

		},
		addPrint() {
			let printer = {
				name: this.name,
				use: this.use
			};

			if (!this.printers.find(el => el.use === this.use)) {
				this.printers.push(printer);
				this.setPrinters();
				this.$toast.success('Impresora agregada');
				this.name = null;
				this.use = null;
			} else {
				this.$toast.error('No se puede agregar la impresora');
			}
		},
		setPrinters() {
			localStorage.setItem('printers', JSON.stringify(this.printers || []));
		},
		onPrintManagerChange(value) {
			console.log('salvando');
			localStorage.setItem('printerManager', value);
		},
    onStoreNoPrinter(){
		  if(this.printer && Number.isInteger(Number.parseInt(this.printer))){
        localStorage.setItem('printerManager_printer', this.printer);
      }else{
        this.$buefy.dialog.alert({
          title: 'Se presentaron los siguientes errores en la validación',
          message: 'El numero de la impresora debe ser un numero mayor o igual a 1'
        });
      }
    }
	}
}
</script>

<style scoped>

</style>
